#root {
  background-color: #f1f1f1;
}

.bg-blue {
  background-color: #003366 !important;
}

.bg-lightblue {
  background-color: #0099cc !important;
}

.bg-black {
  background-color: #242424 !important;
}

.bg-fffff {
  background-color: #ffffff;
}

.bg-darkgrey {
  background-color: #666666 !important;
}

.bg-lightgrey {
  background-color: #dddddd !important;
}

.bg-pink {
  background-color: #cc0066 !important;
}

.c-lightblue {
  color: #189bcc !important;
}

.c-white {
  color: white !important;
}

.whiteDiv {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.overFlowAuto {
  overflow-x: auto;
}

.button-search {
  background-color: #0099cc !important;
  border: none !important;
  color: white !important;
  min-width: 150px;
  min-height: 30px;
  padding: 0.5px !important;
  text-align: center !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
  border-radius: 17px !important;
  margin-left: 2% !important;
  font-size: 14px !important;
}

.button-save {
  background-color: #0099cc;
  border: none;
  color: white;
  min-width: 150px;
  min-height: 30px;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 17px;
  font-size: 12px;
}

.button-cancel {
  background-color: #0099cc00;
  border: solid 2px #0099cc;
  color: #0099cc;
  min-width: 150px;
  min-height: 30px;
  text-align: center;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 17px;
  font-size: 14px;
}

.smallBtn {
  color: white !important;
  background-color: #ffffff !important;
  border: none !important;
  box-shadow: 1px 1px 3px 0px #707070 !important;
  height: 50px !important;
}

input {
  border: 2px solid #d7dae2;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textarea {
  background-color: #eeeeee !important;
  border-radius: unset !important;
  border: none !important;
}

.pointer {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #0099cc !important;
  border-color: white !important;
}

.centerPage {
  /* position: fixed;
  overflow-x: hidden;
  overflow-y: auto; */

  height: 100% !important;
  position: fixed !important;
  z-index: 1 !important;
  top: 0 !important;
  left: 0 !important;
  width: 16.2vw !important;
}

.mainMargin {
  margin-left: 16vw !important;
}

#wrapper {
  overflow-x: hidden;
  overflow-y: auto;
}

.selectGroup {
  display: flex;
}

.statusDiv {
  color: white;
  text-align: center;
  height: 30px;
  margin-top: 4px;
  padding-top: 2px;
}

@media (max-width: 760px) {
  .selectGroup {
    display: inline;
  }

  .centerPage {
    width: 100vw !important;
    position: relative !important;
  }

  .mainMargin {
    margin-left: 0vw !important;
  }

  .mobileMargin {
    padding-top: 35%;
  }
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding-left: 1em;
}

li:before {
  display: inline-block;
  margin-left: -1em; /* same as padding-left set on li */
  width: 1em; /* same as padding-left set on li */
}

.pointer {
  cursor: pointer;
}

.colorHeader {
  color: #242424 !important;
}

/* datepicker */
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.qr {
  background-color: #0099cc;
  color: black;
  width: 100px;
  border: none;
  box-shadow: 1px 1px 3px 0px #707070;
  height: 30px;
}

.smallSquare {
  height: 15px;
  width: 15px;
}

.baseButton {
  background-color: #0099cc;
  color: white;
  min-width: 100px;
  width: 10%;
  height: 35px;
  border: none;
  border-radius: 17px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
